<template>
  <div>
    <div class="flix-form-group" :key="view">
      <editBtn :onClick="function () { setView() }">
        <template v-slot:text>
          <span v-if="view === 'preview'">{{ $t('message.edit', {name: $tc("message.form", 1)}) }}</span>
          <span v-if="view === 'edit'">{{ $t('message.preview') }}</span>
        </template>
      </editBtn>
    </div>
    <div class="flix-form-group flix-text-info" v-if="view === 'preview'">
      <flixIcon :id='"info-sign"' /> <span class="flix-html-strong"> {{ $t('message.preview') }}</span> ({{ $t('message.bookingsNotAllowed') }})
    </div>
    <div style="border: 4px dotted lightgrey; padding: 10px">
      <div>
        <formMenue v-if="view === 'edit'" :form="form" />
      </div>
      <transition name="flixFadeIn">
        <div class='app flix-list-group' :key="view">
          <div v-for="(element, index) in form" :key="index" :style="{width: ((element.columnName/12) * 100)+'%', float:'left', padding:'5px', 'box-sizing':'border-box'}">
            <FormEditor  :form="form" :index="index" :setDelete="function(){ setDelete(index) }" :setMoveUp="function(){ setMoveUp(index) }" :setMoveDown="function(){ setMoveDown(index) }">
              <template :slot="view">
                <span v-if="'Form'+element.type" @click="function(){ setPopup() }">
                  <component  :is="'Form'+element.type"  :data="element" :callback="function(data){ callback(data, element) }" />
                </span>
                <div v-else v-html="element.type"></div>
              </template>
            </FormEditor>
          </div>
        </div>
      </transition>
      <div class="flix-clearfix" />
    </div>
    <flixPopup v-if="popUp" :key="popUp" :time="2000" :type="'warning'">
      <template>
        <div>{{ $t('message.preview') }} - {{ $t('message.bookingsNotAllowed') }}</div>
      </template>
    </flixPopup>
  </div>
</template>
<script>
export default {
  components: {
    Formheader () { return import('@/components/formBuilder/parts/header') },
    Formparagraph () { return import('@/components/formBuilder/parts/paragraph') },
    Formtext () { return import('@/components/formBuilder/parts/text') },
    Formtextarea () { return import('@/components/formBuilder/parts/textarea') },
    Formterms () { return import('@/components/formBuilder/parts/terms') },
    Formemail () { return import('@/components/formBuilder/parts/text') },
    Formradio () { return import('@/components/formBuilder/parts/radio') },
    Formcheckbox () { return import('@/components/formBuilder/parts/checkbox') },
    FormEditor () { return import('@/components/formBuilder/editor') },
    FormMenue () { return import('@/components/formBuilder/menu/index') },
    Formagb () { return import('@/components/formBuilder/parts/agb') }
  },
  props: { form: Array, preview: Boolean, onClick: Function },
  data () {
    return {
      view: 'preview',
      popUp: false
    }
  },
  methods: {
    setPopup () {
      if (this.view === 'preview' && this.preview === true) {
        this.popUp = new Date().getTime()
      }
    },
    setMoveUp (index) {
      if (index === 0) {
        return false
      }
      var from = index
      var to = index - 1
      this.form.splice(to, 0, this.form.splice(from, 1)[0])
    },
    setMoveDown (index) {
      if (index === this.form.length - 1) {
        return false
      }
      var from = index
      var to = index + 1
      this.form.splice(to, 0, this.form.splice(from, 1)[0])
    },
    setDelete (index) {
      this.form.splice(index, 1)
    },
    setView () {
      if (this.view === 'preview') {
        this.view = 'edit'
      } else {
        this.view = 'preview'
      }
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>

</style>
